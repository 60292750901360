<script lang="ts" setup>
import { reactive } from 'vue'
import QRCode from 'qrcode'
import { getProductList, orderProduct } from '@/api'

const valMap: any = reactive({
  productList: [],
  select: 0,
  payType: 3,
  payTypeKV: ['-', '支付宝', '支付宝', '微信'],
})
const getQRCode = () => {
  orderProduct({
    payType: valMap.payType, productId: valMap.productList[valMap.select].id, returnUrl: 'www.baidu.com', source: 10,
  }).then((res) => {
    if (res.code === 200) {
      let canvas = document.getElementById('qrcode')
      let text = null
      if (valMap.payType === 1) {
        text = JSON.parse(res.data.payBody)?.alipay_trade_precreate_response?.qr_code
      } else {
        text = res.data.payUrl
      }
      QRCode.toCanvas(canvas, text, {
        margin: 2,
        height: 256,
        width: 256,
      }, (error: any) => {
        if (error) console.error(error)
        console.log('QRCode success!')
      })
    }
  })
}
const getList = () => {
  getProductList({ source: 10, language: 1 }).then((res) => {
    if (res.code === 200) {
      valMap.productList = res.data
      if (valMap.productList.length) getQRCode()
    }
  })
}
const changeTab = (index: any) => {
  if (valMap.payType !== index) {
    valMap.payType = index
    getQRCode()
  }
}
getList()
const jumpUser = () => {
  window.open('https://leidianshare.mianzhikeji.com/index.html#/thunder/user')
}
const jumpPirv = () => {
  window.open('https://leidianshare.mianzhikeji.com/index.html#/thunder/privacy')
}
</script>
<template>
  <div class="black">
    <div class="background">
      <div class="head" />
      <div class="title">
        会员充值
        <img class="tip" src="@/assets/images/icon_notice.png">
        <div class="tip">官网充值更加优惠，省+省！！！</div>
      </div>
      <div class="tag1">
        <div
          v-for="item, index in valMap.productList" :key="index" class="box"
          :class="{ select: index == valMap.select }" @click="valMap.select = index; getQRCode()">
          <div class="top">{{ item.name }}</div>
          <div class="main">
            <div class="cost">{{ item.saleMonthPrice }}元/月</div>
            <div class="before">{{ item.originalSaleMonthPrice }}元/月</div>
            <div class="total">{{ item.totalSaleContent }}</div>
            <div class="app">APP端{{ item.appTotalSaleContent }}</div>
          </div>
        </div>
      </div>
      <div class="title">支付方式</div>
      <div class="tag2">
        <div class="top">请选择支付方式</div>
        <div class="pay">
          <div class="box" :class="{ select: valMap.payType == 3 }" @click="changeTab(3)"><img class="img" src="@/assets/images/icon_wechat_32.png">微信支付</div>
          <div class="box" :class="{ select: valMap.payType == 1 }" @click="changeTab(1)">
            <img class="img" src="@/assets/images/icon_zhifubao_32.png">支付宝支付
          </div>
          <!-- <div class="box" :class="{ select: valMap.payType == 3 }" @click="valMap.payType = 3"><img class="img" src="@/assets/images/icon_paypal_32.png">PayPal支付</div> -->
        </div>
        <div class="paymain">
          <div class="qcode">
            <div class="qcimg"><canvas id="qrcode" /></div>
            <div class="tip">打开{{ valMap.payTypeKV[valMap.payType] }}扫一扫</div>
          </div>
          <div class="price">
            <div class="top">{{ valMap.productList[valMap.select]?.name || '' }}</div>
            <div class="cost">{{ valMap.productList[valMap.select]?.saleMonthPrice || 0 }}元/月</div>
            <div class="total">
              {{ valMap.productList[valMap.select]?.totalSaleContent || '' }} 已省<span style="color: #FF1E00;">{{
                valMap.productList[valMap.select]?.economizePrice || 0 }}</span>元
            </div>
            <div class="agree">
              支付过程全程加密，保护您的支付安全 开通即代表同意<span
                style="color: #00DAC6;cursor: pointer;"
                @click="jumpUser">《用户协议》</span>与<span
                  style="color: #00DAC6;cursor: pointer;"
                  @click="jumpPirv">《隐私协议》</span>
            </div>
          </div>
        </div>
        <div class="paytip">
          若您使用支付宝扫码付款遇到国外无法付款问题，请关闭手机定位，重启手机，再尝试支付宝扫码支付
        </div>
      </div>
      <div class="title">会员尊享权益</div>
      <div class="tag3">
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／global.png">
          <div class="viptitle">全球加速</div>
          <div class="text">优选国际网络专线，畅享全球稳定网络连接</div>
        </div>
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／acc.png">
          <div class="viptitle">智能加速</div>
          <div class="text">会员享智能路由技术，智能优选最佳线路加速网络</div>
        </div>
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／line.png">
          <div class="viptitle">多条线路</div>
          <div class="text">会员专享国际多条优质线路</div>
        </div>
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／communicate.png">
          <div class="viptitle">通讯加密</div>
          <div class="text">自研加密技术，保护用户隐私，安全上网</div>
        </div>
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／flow.png">
          <div class="viptitle">无限流量</div>
          <div class="text">连接全程不限制加速流量，随用随有</div>
        </div>
        <div class="box">
          <img class="img" src="@/assets/images/icon／vip／service.png">
          <div class="viptitle">客服优先服务</div>
          <div class="text">客服专员优先会员一对一解答问题，服务更贴心</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.black {
  width: 100%;
  background-color: #1f2322;

  .background {
    width: 1200px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 164px 0;
    margin: auto;

    .head {
      width: 1200px;
      height: 300px;
      background: url(@/assets/images/active.png) 0 0/contain no-repeat;
    }

    .title {
      width: 100%;
      font-size: 36px;
      line-height: 50px;
      display: flex;
      align-items: center;
      margin: 60px 0 40px 0;

      img.tip {
        margin: 0 12px 0 30px;
        width: 20px;
        height: 20px;
      }

      div.tip {
        font-size: 16px;
        color: #FF9900;
        line-height: 22px;
      }
    }

    .tag1 {
      display: flex;
      width: 100%;
      overflow-x: auto;

      // &::-webkit-scrollbar {
      //   width: 8px;
      //   height: 8px;
      //   background-color: linear-gradient(to right, #000, #152331);
      // }

      // &::-webkit-scrollbar-thumb {
      //   background-color: rgb(0 0 0 / 100%);
      //   border-radius: 8px;
      // }

      // &::-webkit-scrollbar-track {
      //   background-color: transparent;
      // }

      .box {
        cursor: pointer;
        width: 380px;
        height: 360px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-right: 30px;
        flex-shrink: 0;

        .top {
          background: #93E3DC;
          border-radius: 8px 8px 0px 0px;
          padding-top: 22px;
          display: flex;
          height: 84px;
          justify-content: center;
          font-weight: bold;
          font-size: 28px;
          color: #333333;
          line-height: 40px;
          color: #333;
        }

        .main {
          display: flex;
          flex-direction: column;
          align-items: center;

          .cost {
            margin-top: 30px;
            font-size: 44px;
            color: #00665D;
            line-height: 62px;
            font-weight: bold;
          }

          .before {
            margin-top: 8px;
            font-size: 20px;
            color: #999999;
            line-height: 28px;
            text-decoration-line: line-through;
          }

          .total {
            margin-top: 20px;
            background: rgba(216, 216, 216, 0);
            border-radius: 18px;
            border: 1px solid #CCCCCC;
            width: 160px;
            height: 36px;
            font-size: 18px;
            color: #333333;
            line-height: 36px;
            text-align: center;
          }

          .app {
            margin-top: 32px;
            font-size: 14px;
            color: #999999;
            line-height: 20px;
          }
        }

        &.select {
          background: #D6FBF8;
          border: 4px solid #00DAC6;

          .top {
            background: #00DAC6;
            border-radius: 4px 4px 0px 0px;
            padding-top: 18px;
            height: 80px;
          }
        }
      }
    }

    .tag2 {
      width: 100%;
      background: #F6F8FA;
      border-radius: 8px;
      border: 1px solid rgba(0, 102, 93, 0.1);
      padding: 42px 168px 48px 168px;
      color: #333333;

      .top {
        font-size: 24px;
        line-height: 33px;
      }

      .pay {
        display: flex;
        align-items: center;
        margin: 23px 0 32px 0;

        .box {
          cursor: pointer;
          width: 272px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(246, 248, 250, 0);
          border-radius: 8px;
          border: 2px solid #DFE5E5;
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
          margin-right: 24px;

          .img {
            width: 32px;
            height: 32px;
            margin-right: 12px;
          }

          &.select {
            background: #FFFFFF;
          }
        }
      }

      .paymain {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 332px;
        grid-column-gap: 30px;

        .qcode {
          height: 332px;
          background: #FFFFFF;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 12px;

          .qcimg {
            height: 256px;
            width: 256px;
            margin-bottom: 16px;
          }

          .tip {
            font-size: 20px;
            color: #999999;
            line-height: 28px;
          }
        }

        .price {
          height: 332px;
          background: #FFFFFF;
          border-radius: 8px;
          padding: 32px 36px;

          .top {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 40px;
            font-weight: bold;
          }

          .cost {
            font-size: 40px;
            color: #00665D;
            line-height: 56px;
            font-weight: bold;
            margin-bottom: 39px;
          }

          .total {
            font-size: 24px;
            line-height: 33px;
            font-weight: bold;
            margin-bottom: 28px;
          }

          .agree {
            font-size: 16px;
            color: #808080;
            line-height: 22px;
          }
        }
      }

      .paytip {
        margin-top: 36px;
        font-size: 16px;
        color: #808080;
        line-height: 22px;
      }
    }

    .tag3 {
      display: grid;
      grid-template-columns: repeat(3, 31.7%);
      grid-template-rows: 232px 232px;
      grid-row-gap: 32px;
      grid-column-gap: 30px;
      width: 100%;
      color: #333333;

      .box {
        width: 380px;
        height: 232px;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img {
          width: 72px;
          height: 72px;
          margin: 40px 0 24px 0;
        }

        .viptitle {
          font-weight: bold;
          font-size: 18px;
          line-height: 25px;
        }

        .text {
          font-size: 14px;
          line-height: 20px;
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
